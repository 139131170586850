@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

body {
  margin: 0;
  font-family: 'Aclonica', sans-serif, 'Montserrat',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //height: 100%;
  background-color: black;
  
}
html { height: 100%;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f2f5f7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  /* The image used */
  
  background-image: url("https://res.cloudinary.com/kwesiblack/image/upload/v1598611570/portfolio/IMG_5665-2_qufbb9.jpg");
  //opacity: 0.6;


  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
 
  
  
}

.thirdColumn {
  background-color: black;
}

.pbl-5 {
  padding: ($spacer * 6) !important;
}

.pbl-6 {
  padding: ($spacer * 10) !important;
}

.welcome {
  color:white;
  size:400px ;
  font-family: 'Helvetica Neue';

}
.introColor {
  color:white;
  font-family: 'Montserrat';

}

.introRed {
  color:red;
  font-family: 'Montserrat';

}

.iconColor {
  color:white;
  size:300px ;
  font-family: 'Montserrat';

  @include hover() {
    color: red;
    text-decoration: none;
  }
  
}
.alert {
  font-family: 'Helvetica Neue';
  color:white;
}

//
// Tabs
//

.nav-tabs {
  border-bottom:  0 ;

  a {
    color: white;
    text-decoration: none;
    background-color: transparent;
}

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: 1px solid transparent;
   // @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: white;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    //color: $nav-tabs-link-active-color;
    //background-color: $nav-tabs-link-active-bg;
    border-color: white;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
   // color: $nav-pills-link-active-color;
    //background-color: $nav-pills-link-active-bg;
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 2;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}



// Tree


#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #373737;
  overflow: hidden;
  font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
}
html,
body,
div,
a,
i,
button,
select,
option,
optgroup,
hr,
br {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.treeview > .toggle {
  pointer-events: all;
  cursor: pointer;
}
.treeview > .toggle:hover {
  color: #63b1de;
  fill: #63b1de;
}


.accordion{

  .card{
    background-color: transparent;
  }
}

.text-red {
  color:red
}
.card{
  background-color: transparent;
}